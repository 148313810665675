import React from 'react';
import styles from './ButtonLink.module.css';
import classNames from 'classnames';

interface IButtonLink extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  to: string;
  css?: string;
}

export const ButtonLink: React.FC<IButtonLink> = ({ children, to, css }) => {
  let style = classNames(styles.btn, styles.container, css);
  return (
    <a className={style} href={to}>
      {children}
    </a>
  );
};
