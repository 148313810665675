// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Specification-module__wrapper___91rLN {
  display: flex;
  width: 1238px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.Specification-module__title___vg_sw {
  font: var(--font-h2);
  color: var(--color-black);
  text-align: center;
}

.Specification-module__title___vg_sw span {
  color: var(--color-primary);
}

@media (min-width: 768px) and (max-width: 1199px) {
  .Specification-module__wrapper___91rLN {
    width: 692px;
    gap: 40px;
  }
}

@media (min-width: 360px) and (max-width: 767px) {

  .Specification-module__wrapper___91rLN {
    width: 324px;
    gap: 25px;
  }

}`, "",{"version":3,"sources":["webpack://./src/widgets/specifications/ui/Specification.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,YAAY;IACZ,SAAS;EACX;AACF;;AAEA;;EAEE;IACE,YAAY;IACZ,SAAS;EACX;;AAEF","sourcesContent":[".wrapper {\n  display: flex;\n  width: 1238px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 60px;\n}\n\n.title {\n  font: var(--font-h2);\n  color: var(--color-black);\n  text-align: center;\n}\n\n.title span {\n  color: var(--color-primary);\n}\n\n@media (min-width: 768px) and (max-width: 1199px) {\n  .wrapper {\n    width: 692px;\n    gap: 40px;\n  }\n}\n\n@media (min-width: 360px) and (max-width: 767px) {\n\n  .wrapper {\n    width: 324px;\n    gap: 25px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Specification-module__wrapper___91rLN`,
	"title": `Specification-module__title___vg_sw`
};
export default ___CSS_LOADER_EXPORT___;
