import optf from '../../../shared/assets/img/oprf.png';
import rsv from '../../../shared/assets/img/rsv.png';
import cls from './Partners.module.css';

interface IInstallation {}

export const Partners: React.FC<IInstallation> = () => {
  return (
    <div className={cls.partners}>
      <a className={cls.oprf} href="https://www.oprf.ru" target="_blank">
        <img src={optf} alt="Общественная палата Российской Федерации" />
      </a>

      <a className={cls.rsv} href="https://www.rsv.ru/" target="_blank">
        <img src={rsv} alt="Россия - страна возможностей" />
      </a>
    </div>
  );
};
