import React from 'react';
import styles from './MainScreen.module.css';
import { Shield } from '../../../entities/shield';
import { Shops } from '../../../features/shops';
import icon from '../../../shared/assets/img/icon-logo.png';
import image from '../../../shared/assets/img/img-promo.png';
import ru from '../../../shared/assets/img/image_ru.png';
import ru_tel from '../../../shared/assets/img/image_ru_tel.png';
interface IMainScreen { }

export const MainScreen: React.FC<IMainScreen> = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>  
        <div className={styles.cose}>
        <div className={styles.casket}>
        <Shield icon={icon} text="Приложение с позитивным контентом" />
        <div>
          <h1 className={styles.title}>
            Приложение <br />
            <span>«Хорошие новости»</span>
          </h1>
          <p className={styles.text}>
          Получайте проверенные новости о&nbsp;достижениях нашей страны 
          и&nbsp;людях, которыми может гордиться наше общество! 
          Участвуйте в&nbsp;распространении этого контента и&nbsp;получайте
           <a className={styles.link} href='https://goodmarket.oprf.ru/' target="_blank"> призы</a> за&nbsp;свою активность!
          </p>
          </div>
          <div className={styles.indent}>
          <p className={styles.word}>Нажмите, чтобы скачать приложение:</p>

          <Shops />
          </div>
        </div>
        <div className={styles.image}>
        <img src={image} alt="Хорошие новости" />
      </div>
      </div>
    <a href='https://premiaruneta.ru/' className={styles.cover} target="_blank">
      <div className={styles.container}>
        <div className={styles.bonus}>
          <p className={styles.laureate}>Лауреат</p>
          <p className={styles.runet}>Премии Рунета 2023</p>
        </div>
        <a href='https://premiaruneta.ru/' className={styles.ru} target="_blank">premiaruneta.ru</a>
      </div>
      <div className={styles.img}>
      <img src={ru} alt="ru" className={styles.imgs} />
      </div>
      <div className={styles.imgTel}>
      <img src={ru_tel} alt="ru_tel" className={styles.imgsTel} />
      </div>
    </a>   
      </div>
    </div>
  );
};
