import React from 'react';
import styles from './Specification.module.css';
import { BlockCards } from '../../../entities/blockCards';

interface ISpecifications {}

export const Specifications: React.FC<ISpecifications> = () => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        <span>В приложении</span> вы можете
      </h2>
      <BlockCards />
    </div>
  );
};
