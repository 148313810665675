import React from 'react';
import partner from '../../../shared/assets/img/group.svg';
import logo from '../../../shared/assets/img/logo.svg';
import { ButtonLink } from '../../../shared/ui/ButtonLink/ButtonLink';
import styles from './Navbar.module.css';

interface INavbar {}

export const Navbar: React.FC<INavbar> = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftside}>
        <a href="#" className={styles.logo}>
          <img src={logo} alt="Хорошие новости" />
        </a>
      </div>
      <div className={styles.rightside}>
        <ButtonLink to="mailto:goodnews@oprf.ru">
          <div className={styles.icon}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g id="ic-24/mail">
                <path
                  id="Vector (Stroke)"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.125 5.5C3.64175 5.5 3.25 5.89175 3.25 6.375V17.625C3.25 18.1082 3.64175 18.5 4.125 18.5H19.875C20.3582 18.5 20.75 18.1082 20.75 17.625V6.375C20.75 5.89175 20.3582 5.5 19.875 5.5H4.125ZM1.25 6.375C1.25 4.78718 2.53718 3.5 4.125 3.5H19.875C21.4628 3.5 22.75 4.78718 22.75 6.375V17.625C22.75 19.2128 21.4628 20.5 19.875 20.5H4.125C2.53718 20.5 1.25 19.2128 1.25 17.625V6.375ZM4.46065 6.88606C4.79972 6.45011 5.42799 6.37158 5.86394 6.71065L12 11.4831L18.1361 6.71065C18.572 6.37158 19.2003 6.45011 19.5394 6.88606C19.8784 7.32201 19.7999 7.95028 19.3639 8.28935L12.6139 13.5394C12.2528 13.8202 11.7472 13.8202 11.3861 13.5394L4.63606 8.28935C4.20011 7.95028 4.12158 7.32201 4.46065 6.88606Z"
                />
              </g>
            </svg>
          </div>
          <div>goodnews@oprf.ru</div>
        </ButtonLink>
      </div>
    </div>
  );
};
