import React from 'react';
import styles from './List.module.css';
import { ListItem } from '../../listItem';

interface IList {
  list: IItemList[];
}

export interface IItemList {
  number: string;
  title: string;
  exile?: string;
  text?: string;
  to?: string;
}

export const List: React.FC<IList> = ({ list }) => {
  return (
    <div className={styles.wrapper}>
      {list.map((item) => {
        return (
          <ListItem number={item.number} title={item.title} exile={item.exile} text={item.text} to={item.to} />
        );
      })}
    </div>
  );
};
