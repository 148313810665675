// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List-module__wrapper___eQHuw {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .List-module__wrapper___eQHuw {
    gap: 20px;
  }
}

@media (min-width: 360px) and (max-width: 767px) {

  .List-module__wrapper___eQHuw {
    gap: 25px
  }

}`, "",{"version":3,"sources":["webpack://./src/entities/list/ui/List.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE;IACE,SAAS;EACX;AACF;;AAEA;;EAEE;IACE;EACF;;AAEF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 60px;\n}\n\n@media (min-width: 768px) and (max-width: 1199px) {\n  .wrapper {\n    gap: 20px;\n  }\n}\n\n@media (min-width: 360px) and (max-width: 767px) {\n\n  .wrapper {\n    gap: 25px\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `List-module__wrapper___eQHuw`
};
export default ___CSS_LOADER_EXPORT___;
