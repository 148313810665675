import React from 'react';
import image from '../../../shared/assets/img/img-stor.png';
import apk from '../../../shared/assets/img/buttons-apk.png';
import googlePlay from '../../../shared/assets/img/google-play.png';
import appStore from '../../../shared/assets/img/app-store.png';
import appGallery from '../../../shared/assets/img/app-gallery.png';
import ruStore from '../../../shared/assets/img/rustore.png';
import { ButtonStoreLink } from '../../../shared/ui/ButtonStoreLink/ButtonStoreLink';
import { ButtonStoreaAnchor } from '../../../shared/ui/ButtonStoreaAnchor/ButtonStoreaAnchor';
import styles from './Promotion.module.css';

interface IPromotion { }

export const Promotion: React.FC<IPromotion> = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h4 className={styles.title}>
          Подробная информация о&nbsp;правилах участия и&nbsp;поощрениях для
          активных
          пользователей&nbsp;&mdash; в&nbsp;приложении!
        </h4>

        <div className={styles.wrap}>
          <ButtonStoreLink to="https://apps.rustore.ru/app/ru.platforma.uchastie.app">
            <img src={ruStore} alt="RuStore" className={styles.store} />
          </ButtonStoreLink>
          <ButtonStoreLink to="https://appgallery.huawei.com/app/C109159009">
            <img src={appGallery} alt="App Gallery" className={styles.store} />
          </ButtonStoreLink>
          <ButtonStoreaAnchor to="#apk">
            <img src={apk} alt="Apk android" className={styles.store} />
          </ButtonStoreaAnchor>
          <ButtonStoreLink to="https://play.google.com/store/apps/details?id=ru.platforma.uchastie.app&pli=1">
            <img src={googlePlay} alt="Google Play" className={styles.store} />
          </ButtonStoreLink>
          <ButtonStoreLink to="https://apps.apple.com/ru/app/%D1%85%D0%BE%D1%80%D0%BE%D1%88%D0%B8%D0%B5-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D0%B8/id6468446555">
            <img src={appStore} alt="App Store" className={styles.store} />
          </ButtonStoreLink>
        </div>
      </div>
      <div className={styles.image}>
        <img src={image} alt="Хорошие новости" />
      </div>
    </div>
  );
};
