// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-module__container___VXMUE {
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding: 0px 101px 100px 101px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  gap: 100px;
}

.App-module__content___Oo60W {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .App-module__container___VXMUE {
    padding: 0px 38px 60px 38px;
    gap: 60px;
  }

  .App-module__content___Oo60W {
    gap: 30px;
  }
}


@media (min-width: 360px) and (max-width: 767px) {
  .App-module__container___VXMUE {
    padding: 10px 18px 30px 18px;
    gap: 60px;
  }

  .App-module__content___Oo60W {
    gap: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/styles/module/App.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,8BAA8B;EAC9B,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,WAAW;AACb;;AAEA;EACE;IACE,2BAA2B;IAC3B,SAAS;EACX;;EAEA;IACE,SAAS;EACX;AACF;;;AAGA;EACE;IACE,4BAA4B;IAC5B,SAAS;EACX;;EAEA;IACE,SAAS;EACX;AACF","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n  max-width: 1440px;\n  padding: 0px 101px 100px 101px;\n  margin-left: auto;\n  margin-right: auto;\n  flex-direction: column;\n  align-items: center;\n  gap: 100px;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 60px;\n  width: 100%;\n}\n\n@media (min-width: 768px) and (max-width: 1199px) {\n  .container {\n    padding: 0px 38px 60px 38px;\n    gap: 60px;\n  }\n\n  .content {\n    gap: 30px;\n  }\n}\n\n\n@media (min-width: 360px) and (max-width: 767px) {\n  .container {\n    padding: 10px 18px 30px 18px;\n    gap: 60px;\n  }\n\n  .content {\n    gap: 25px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `App-module__container___VXMUE`,
	"content": `App-module__content___Oo60W`
};
export default ___CSS_LOADER_EXPORT___;
