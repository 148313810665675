// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonLink-module__btn___kTT6E {
  border: none;
  outline: none;
  background: none;
  -webkit-text-decoration: none;
  text-decoration: none;

  font: var(--font-button);
  color: var(--color-surface);

  padding: 16px;
  border-radius: 100px;
  border: 1px solid var(--color-surface);
  background: var(--color-primary);

  transition: all 0.2s ease-in-out;

  fill: var(--color-surface);
}

.ButtonLink-module__container___AdIDn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.ButtonLink-module__btn___kTT6E:hover,
.ButtonLink-module__btn___kTT6E:active {
  background: var(--color-secondary);
}

.ButtonLink-module__btn___kTT6E:focus {
  border: 1px solid var(--color-primary);
  background: var(--color-surface);
  color: var(--color-primary);
  fill: var(--color-primary);
}

.ButtonLink-module__btn___kTT6E:disabled,
.ButtonLink-module__btn___kTT6E[disabled] {
  border: 1px solid var(--color-background-surface);
  background: var(--color-disabled-bg);
  color: var(--color-disabled-text);
}

@media (min-width: 768px) and (max-width: 1199px) {

  .ButtonLink-module__container___AdIDn {
    height: 66px;
    padding: 12px 35px 13px 35px;
  }
}

@media (min-width: 360px) and (max-width: 767px) {

  .ButtonLink-module__container___AdIDn {
    height: 45px;
    padding: 0px 72px;
  }

}`, "",{"version":3,"sources":["webpack://./src/shared/ui/ButtonLink/ButtonLink.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,6BAAqB;EAArB,qBAAqB;;EAErB,wBAAwB;EACxB,2BAA2B;;EAE3B,aAAa;EACb,oBAAoB;EACpB,sCAAsC;EACtC,gCAAgC;;EAEhC,gCAAgC;;EAEhC,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;;EAEE,kCAAkC;AACpC;;AAEA;EACE,sCAAsC;EACtC,gCAAgC;EAChC,2BAA2B;EAC3B,0BAA0B;AAC5B;;AAEA;;EAEE,iDAAiD;EACjD,oCAAoC;EACpC,iCAAiC;AACnC;;AAEA;;EAEE;IACE,YAAY;IACZ,4BAA4B;EAC9B;AACF;;AAEA;;EAEE;IACE,YAAY;IACZ,iBAAiB;EACnB;;AAEF","sourcesContent":[".btn {\n  border: none;\n  outline: none;\n  background: none;\n  text-decoration: none;\n\n  font: var(--font-button);\n  color: var(--color-surface);\n\n  padding: 16px;\n  border-radius: 100px;\n  border: 1px solid var(--color-surface);\n  background: var(--color-primary);\n\n  transition: all 0.2s ease-in-out;\n\n  fill: var(--color-surface);\n}\n\n.container {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n}\n\n.btn:hover,\n.btn:active {\n  background: var(--color-secondary);\n}\n\n.btn:focus {\n  border: 1px solid var(--color-primary);\n  background: var(--color-surface);\n  color: var(--color-primary);\n  fill: var(--color-primary);\n}\n\n.btn:disabled,\n.btn[disabled] {\n  border: 1px solid var(--color-background-surface);\n  background: var(--color-disabled-bg);\n  color: var(--color-disabled-text);\n}\n\n@media (min-width: 768px) and (max-width: 1199px) {\n\n  .container {\n    height: 66px;\n    padding: 12px 35px 13px 35px;\n  }\n}\n\n@media (min-width: 360px) and (max-width: 767px) {\n\n  .container {\n    height: 45px;\n    padding: 0px 72px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `ButtonLink-module__btn___kTT6E`,
	"container": `ButtonLink-module__container___AdIDn`
};
export default ___CSS_LOADER_EXPORT___;
