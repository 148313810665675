import React from 'react';
import circle1 from '../../../shared/assets/img/icon-circle1.png';
import circle2 from '../../../shared/assets/img/icon-circle2.png';
import circle3 from '../../../shared/assets/img/icon-circle3.png';
import waiting from '../../../shared/assets/img/icon-waiting.png';

import styles from './Waiting.module.css';

interface IWaiting { }

export const Waiting: React.FC<IWaiting> = () => {
  return (
    <div className={styles.wrapper}>


      <div className={styles.title}>
              <p className={styles.textBig}>В мобильном приложении «Хорошие новости» запустился 
              <span> проект для Лидеров мнений </span>
                совместно с президентской платформой «Россия – страна возможностей»!</p>

          <p className={styles.text}>Мы рады сообщить о начале сотрудничества с президентской платформой 
          «Россия – страна возможностей» и запуске проекта для Лидеров мнений, направленного на выявление, обучение 
          и поддержку активных молодых людей, которые распространяют социально значимый позитивный контент</p>
      </div>
        <div className={styles.rede}>Что ждет участников?</div>

     <div className={styles.case}>
      <div className={styles.topic}>
        <img className={styles.img} src={circle1} alt="circle1" />
        <div className={styles.cask}>
          <p className={styles.textBig}>Образовательные марафоны</p>
          <p className={styles.text}>по медиаграмотности и созданию качественного контента</p>
        </div>
      </div>

      <div className={styles.topic}>
          <img className={styles.img} src={circle2} alt="circle2" />
        <div className={styles.cask}>
          <p className={styles.textBig}>Тематические треки,</p>
          <p className={styles.text}>посвященные достижениям России, инновационным проектам, культуре и образованию</p>
        </div>
      </div>

      <div className={styles.topic}>
            <img className={styles.img} src={circle3} alt="circle3" />
        <div className={styles.cask}>
          <p className={styles.textBig}>Система мотивации</p>
          <p className={styles.text}>с уникальными стажировками, благодарственными письмами и участием в федеральных форумах</p>
        </div>
      </div>
     </div>
     <div className={styles.code}>Как присоединиться?</div>

     <div className={styles.wrap}>
      <img className={styles.img} src={waiting} alt="waiting" />
      <div className={styles.container}>
        <p className={styles.scriptBig}>Зарегистрируйтесь в проекте через приложение «Хорошие новости» или <a
                          className={styles.link}
                          href="https://webgoodnews.oprf.ru/"
                          target="_blank"
                        >
                          {' '}
                          веб-платформу проекта
                        </a>{' '}</p>
        <p className={styles.scriptBig}>После регистрации вы получите доступ к образовательным марафонам и заданиям </p>
      </div>

      
      <p className={styles.script}>Участвовать могут все пользователи, независимо от количества подписчиков, 
        но к окончанию этапа проекта в 2025 году у участников должно быть не менее 2000 подписчиков</p>
     </div>

    </div>
  );
};
