import React from 'react';
import styles from './Footer.module.css';
import { TextLink } from '../../../shared/ui/TextLink/TextLink';
import { ButtonLink } from '../../../shared/ui/ButtonLink/ButtonLink';
import img from '../../../shared/assets/img/logo-min.svg';


interface IFooter { }

export const Footer: React.FC<IFooter> = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.linksBox}>
          <div className={styles.image}>
            <img src={img} alt="Хорошие новости" />
          </div>
          <div>
            <div className={styles.links}>
              <TextLink to="/pdf/terms-use.pdf">Пользовательское соглашение</TextLink>
              <TextLink to="/pdf/policy.pdf">Политика конфеденциальности</TextLink>
              <TextLink to="/pdf/confirm-questions.pdf">Согласие на обработку персональных данных
              </TextLink>
            </div>
          </div>
        </div>
        <div>
          <ButtonLink to="mailto:goodnews@oprf.ru">
            <div className={styles.icon}>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g id="ic-24/mail">
                  <path
                    id="Vector (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.125 5.5C3.64175 5.5 3.25 5.89175 3.25 6.375V17.625C3.25 18.1082 3.64175 18.5 4.125 18.5H19.875C20.3582 18.5 20.75 18.1082 20.75 17.625V6.375C20.75 5.89175 20.3582 5.5 19.875 5.5H4.125ZM1.25 6.375C1.25 4.78718 2.53718 3.5 4.125 3.5H19.875C21.4628 3.5 22.75 4.78718 22.75 6.375V17.625C22.75 19.2128 21.4628 20.5 19.875 20.5H4.125C2.53718 20.5 1.25 19.2128 1.25 17.625V6.375ZM4.46065 6.88606C4.79972 6.45011 5.42799 6.37158 5.86394 6.71065L12 11.4831L18.1361 6.71065C18.572 6.37158 19.2003 6.45011 19.5394 6.88606C19.8784 7.32201 19.7999 7.95028 19.3639 8.28935L12.6139 13.5394C12.2528 13.8202 11.7472 13.8202 11.3861 13.5394L4.63606 8.28935C4.20011 7.95028 4.12158 7.32201 4.46065 6.88606Z"
                  />
                </g>
              </svg>
            </div>
            <div>goodnews@oprf.ru</div>
          </ButtonLink>
        </div>
      </div>
      <div className={styles.casket}>

        <div className={styles.case}>
          <p className={styles.caption}>Контакты:</p>
          <p className={styles.version}>Адрес:<p className={styles.facts}>
            125047, г. Москва,<br></br> Миусская пл., д. 7 стр. 1</p></p>
          <p className={styles.version}>E-mail:<a className={styles.facts}
            href="mailto:goodnews@oprf.ru">goodnews@oprf.ru</a></p>
          <p className={styles.version}>Тел:<p className={styles.facts}>8 (495) 132-59-99</p></p>
        </div>

        <div className={styles.case}>
          <p className={styles.caption}>Реквизиты:</p>
          <p className={styles.organ}>Федеральное казенное учреждение
            <br></br>Аппарат Общественной палаты Российской Федерации</p>
          <p className={styles.version}>ИНН:<p className={styles.facts}>7707565512</p></p>
          <p className={styles.version}>КПП:<p className={styles.facts}>770701001</p></p>
          <p className={styles.version}>ОГРН:<p className={styles.facts}>1057748857583</p></p>
        </div>
      </div>
    </div>

  );
};