// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Steps-module__wrapper___QdHey {
  display: flex;
  width: 1238px;
  padding: 100px 60px;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  border-radius: 42px;
  background: var(--color-element-bg);
}

.Steps-module__title___TPJTi {
  font: var(--font-h2);
  color: var(--color-black);
  text-align: center;
}

.Steps-module__title___TPJTi span {
  color: var(--color-primary);
}

.Steps-module__content___fHXxu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Steps-module__image___hIEaT {
  width: 440px;
  height: 564px;
  padding: 14px 0px;
}

.Steps-module__image___hIEaT img {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1199px) {

  .Steps-module__wrapper___QdHey {
    width: 692px;
    padding: 80px 30px;
    gap: 40px;
  }

  .Steps-module__content___fHXxu {
    gap: 34px;
  }

  .Steps-module__image___hIEaT {
    width: 293px;
    height: 357px;
    flex-shrink: 0;
  }

}

@media (min-width: 360px) and (max-width: 767px) {

  .Steps-module__wrapper___QdHey {
    width: 324px;
    padding: 30px 18px;
    gap: 25px;
    border-radius: 36px;
  }

  .Steps-module__content___fHXxu {
    flex-direction: column;
  }

  .Steps-module__image___hIEaT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 288px;
    height: 368px;
    padding: 9px 0.235px;
  }

}`, "",{"version":3,"sources":["webpack://./src/widgets/steps/ui/Steps.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,mBAAmB;EACnB,mCAAmC;AACrC;;AAEA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;;EAEE;IACE,YAAY;IACZ,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,cAAc;EAChB;;AAEF;;AAEA;;EAEE;IACE,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,oBAAoB;EACtB;;AAEF","sourcesContent":[".wrapper {\n  display: flex;\n  width: 1238px;\n  padding: 100px 60px;\n  flex-direction: column;\n  align-items: center;\n  gap: 60px;\n  border-radius: 42px;\n  background: var(--color-element-bg);\n}\n\n.title {\n  font: var(--font-h2);\n  color: var(--color-black);\n  text-align: center;\n}\n\n.title span {\n  color: var(--color-primary);\n}\n\n.content {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n.image {\n  width: 440px;\n  height: 564px;\n  padding: 14px 0px;\n}\n\n.image img {\n  width: 100%;\n}\n\n@media (min-width: 768px) and (max-width: 1199px) {\n\n  .wrapper {\n    width: 692px;\n    padding: 80px 30px;\n    gap: 40px;\n  }\n\n  .content {\n    gap: 34px;\n  }\n\n  .image {\n    width: 293px;\n    height: 357px;\n    flex-shrink: 0;\n  }\n\n}\n\n@media (min-width: 360px) and (max-width: 767px) {\n\n  .wrapper {\n    width: 324px;\n    padding: 30px 18px;\n    gap: 25px;\n    border-radius: 36px;\n  }\n\n  .content {\n    flex-direction: column;\n  }\n\n  .image {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    width: 288px;\n    height: 368px;\n    padding: 9px 0.235px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Steps-module__wrapper___QdHey`,
	"title": `Steps-module__title___TPJTi`,
	"content": `Steps-module__content___fHXxu`,
	"image": `Steps-module__image___hIEaT`
};
export default ___CSS_LOADER_EXPORT___;
