// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextLink-module__a___G2H3z {
  border: none;
  outline: none;
  background: none;
  -webkit-text-decoration: none;
  text-decoration: none;

  font: var(--font-main);
  color: var(--color-black);
}

.TextLink-module__a___G2H3z:hover,
.TextLink-module__a___G2H3z:active,
.TextLink-module__a___G2H3z:focus {
  color: var(--color-secondary);
}

.TextLink-module__a___G2H3z:disabled,
.TextLink-module__a___G2H3z[disabled] {
  color: var(--color-disabled-text);
}

@media (min-width: 768px) and (max-width: 1199px) {
  .TextLink-module__a___G2H3z {
    font-size: 12px;
  }
}

@media (min-width: 360px) and (max-width: 767px) {

  .TextLink-module__a___G2H3z {
    font-size: 12px;
  }

}`, "",{"version":3,"sources":["webpack://./src/shared/ui/TextLink/TextLink.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,6BAAqB;EAArB,qBAAqB;;EAErB,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;;;EAGE,6BAA6B;AAC/B;;AAEA;;EAEE,iCAAiC;AACnC;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;;EAEE;IACE,eAAe;EACjB;;AAEF","sourcesContent":[".a {\n  border: none;\n  outline: none;\n  background: none;\n  text-decoration: none;\n\n  font: var(--font-main);\n  color: var(--color-black);\n}\n\n.a:hover,\n.a:active,\n.a:focus {\n  color: var(--color-secondary);\n}\n\n.a:disabled,\n.a[disabled] {\n  color: var(--color-disabled-text);\n}\n\n@media (min-width: 768px) and (max-width: 1199px) {\n  .a {\n    font-size: 12px;\n  }\n}\n\n@media (min-width: 360px) and (max-width: 767px) {\n\n  .a {\n    font-size: 12px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": `TextLink-module__a___G2H3z`
};
export default ___CSS_LOADER_EXPORT___;
