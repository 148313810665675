// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Shield-module__wrapper___oPQsf {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 100px;
  background: var(--color-surface);
  box-shadow: 0px 2px 8px 0px rgba(0, 13, 38, 0.1);
  width: 383px;
  height: 58px;
}

.Shield-module__icon___T6zOV {
  width: 42px;
  height: 42px;
}

.Shield-module__text___Y8e0e {
  font: var(--font-main);
  text-align: center;
  color: var(--color-black);
}

@media (min-width: 768px) and (max-width: 1199px) {

  .Shield-module__wrapper___oPQsf{
    width: 314px;
    height: 58px;
  }

  .Shield-module__text___Y8e0e {
    text-align: left;
    text-align: initial;
  }
}

@media (min-width: 360px) and (max-width: 767px) {
  .Shield-module__wrapper___oPQsf {
    width: 228px;
    height: 36px;
    padding: 4px 16px;
  }

  .Shield-module__icon___T6zOV {
    width: 24px;
    height: 24px;
  }

  .Shield-module__text___Y8e0e {
    text-align: left;
    text-align: initial;
  }
}`, "",{"version":3,"sources":["webpack://./src/entities/shield/ui/Shield.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,iBAAiB;EACjB,oBAAoB;EACpB,gCAAgC;EAChC,gDAAgD;EAChD,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;;EAEE;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,gBAAmB;IAAnB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,YAAY;IACZ,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,gBAAmB;IAAnB,mBAAmB;EACrB;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 16px;\n  border-radius: 100px;\n  background: var(--color-surface);\n  box-shadow: 0px 2px 8px 0px rgba(0, 13, 38, 0.1);\n  width: 383px;\n  height: 58px;\n}\n\n.icon {\n  width: 42px;\n  height: 42px;\n}\n\n.text {\n  font: var(--font-main);\n  text-align: center;\n  color: var(--color-black);\n}\n\n@media (min-width: 768px) and (max-width: 1199px) {\n\n  .wrapper{\n    width: 314px;\n    height: 58px;\n  }\n\n  .text {\n    text-align: initial;\n  }\n}\n\n@media (min-width: 360px) and (max-width: 767px) {\n  .wrapper {\n    width: 228px;\n    height: 36px;\n    padding: 4px 16px;\n  }\n\n  .icon {\n    width: 24px;\n    height: 24px;\n  }\n\n  .text {\n    text-align: initial;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Shield-module__wrapper___oPQsf`,
	"icon": `Shield-module__icon___T6zOV`,
	"text": `Shield-module__text___Y8e0e`
};
export default ___CSS_LOADER_EXPORT___;
