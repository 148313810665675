// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonStoreaAnchor-module__btn___bbJTq {
  border: none;
  outline: none;
  background: none;
  -webkit-text-decoration: none;
  text-decoration: none;

  font: var(--font-button);
  color: var(--color-surface);

  padding: 12px 16px;
  border-radius: 16px;
  border: 1px solid var(--color-element-bg);
  background: var(--color-surface);
  box-shadow: 0px 2px 8px 0px rgba(0, 13, 38, 0.1);

  transition: all 0.2s ease-in-out;
}

.ButtonStoreaAnchor-module__container___vPxQM {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.ButtonStoreaAnchor-module__btn___bbJTq:hover,
.ButtonStoreaAnchor-module__btn___bbJTq:active {
  background: var(--color-primary);
}

.ButtonStoreaAnchor-module__btn___bbJTq:focus {
  border: 1px solid var(--color-primary);
  background: var(--color-surface);
}

.ButtonStoreaAnchor-module__btn___bbJTq:disabled,
.ButtonStoreaAnchor-module__btn___bbJTq[disabled] {
  background: var(--color-disabled-bg);
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/ButtonStoreaAnchor/ButtonStoreaAnchor.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,6BAAqB;EAArB,qBAAqB;;EAErB,wBAAwB;EACxB,2BAA2B;;EAE3B,kBAAkB;EAClB,mBAAmB;EACnB,yCAAyC;EACzC,gCAAgC;EAChC,gDAAgD;;EAEhD,gCAAgC;AAClC;;AAEA;EACE,oBAAoB;EACpB,uBAAuB;EACvB,uBAAuB;EACvB,SAAS;AACX;;AAEA;;EAEE,gCAAgC;AAClC;;AAEA;EACE,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;;EAEE,oCAAoC;AACtC","sourcesContent":[".btn {\n  border: none;\n  outline: none;\n  background: none;\n  text-decoration: none;\n\n  font: var(--font-button);\n  color: var(--color-surface);\n\n  padding: 12px 16px;\n  border-radius: 16px;\n  border: 1px solid var(--color-element-bg);\n  background: var(--color-surface);\n  box-shadow: 0px 2px 8px 0px rgba(0, 13, 38, 0.1);\n\n  transition: all 0.2s ease-in-out;\n}\n\n.container {\n  display: inline-flex;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 10px;\n}\n\n.btn:hover,\n.btn:active {\n  background: var(--color-primary);\n}\n\n.btn:focus {\n  border: 1px solid var(--color-primary);\n  background: var(--color-surface);\n}\n\n.btn:disabled,\n.btn[disabled] {\n  background: var(--color-disabled-bg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `ButtonStoreaAnchor-module__btn___bbJTq`,
	"container": `ButtonStoreaAnchor-module__container___vPxQM`
};
export default ___CSS_LOADER_EXPORT___;
