// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Shops-module__wrapper___dbp4S {
  display: flex;
  width: 622px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  flex-wrap: wrap;
}

.Shops-module__wrap___OVpTE {
  display: flex;
  justify-content: flex-start;
}

.Shops-module__store___AWr6N {
  width: 118px;
  height: 30px;
}

@media (min-width: 768px) and (max-width: 1199px) {

  .Shops-module__wrapper___dbp4S {
    width: 428px;
  }

}

@media (min-width: 360px) and (max-width: 767px) {

  .Shops-module__wrapper___dbp4S {
    width: 288px;
    gap: 8px;
    justify-content: center;
  }

  .Shops-module__store___AWr6N {
    width: 93px;
    height: 23px;
  }

}`, "",{"version":3,"sources":["webpack://./src/features/shops/ui/Shops.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,2BAA2B;EAC3B,uBAAuB;EACvB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;;EAEE;IACE,YAAY;EACd;;AAEF;;AAEA;;EAEE;IACE,YAAY;IACZ,QAAQ;IACR,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;AAEF","sourcesContent":[".wrapper {\n  display: flex;\n  width: 622px;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 14px;\n  flex-wrap: wrap;\n}\n\n.wrap {\n  display: flex;\n  justify-content: flex-start;\n}\n\n.store {\n  width: 118px;\n  height: 30px;\n}\n\n@media (min-width: 768px) and (max-width: 1199px) {\n\n  .wrapper {\n    width: 428px;\n  }\n\n}\n\n@media (min-width: 360px) and (max-width: 767px) {\n\n  .wrapper {\n    width: 288px;\n    gap: 8px;\n    justify-content: center;\n  }\n\n  .store {\n    width: 93px;\n    height: 23px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Shops-module__wrapper___dbp4S`,
	"wrap": `Shops-module__wrap___OVpTE`,
	"store": `Shops-module__store___AWr6N`
};
export default ___CSS_LOADER_EXPORT___;
