import React from 'react';
import styles from './ButtonStoreLink.module.css';
import classNames from 'classnames';

interface IButtonStoreLink
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  to: string;
  css?: string;
}

export const ButtonStoreLink: React.FC<IButtonStoreLink> = ({
  children,
  to,
  css,
}) => {
  let style = classNames(styles.btn, styles.container, css);
  return (
    <a className={style} href={to} target="_blank">
      {children}
    </a>
  );
};
