import React from 'react';
import styles from './Shield.module.css';

interface IShield {
  icon: string;
  text: string;
}

export const Shield: React.FC<IShield> = ({ icon, text }) => {
  return (
    <div className={styles.wrapper}>
      <img src={icon} alt="Иконка" className={styles.icon} />
      <p className={styles.text}>{text}</p>
    </div>
  );
};
