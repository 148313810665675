import React from 'react';
import styles from './Steps.module.css';
import { List } from '../../../entities/list';
import leftImg from '../../../shared/assets/img/img-list-1.png';
import rightImg from '../../../shared/assets/img/img-list-2.png';
import type { IItemList } from '../../../entities/list';
interface ISteps { }

export const Steps: React.FC<ISteps> = () => {
  let firstSteps: Array<IItemList> = [
    {
      number: '01',
      title: 'Скачайте приложение, зарегистрируйтесь',
      text: `Ваши персональные данные не будут ${'\n'}видны другим пользователям`,
    },
    {
      number: '02',
      title: 'Выберете интересные вам темы',
      text: `по которым вы будете получать новости в ${'\n'}приложении`,
    },
    {
      number: '03',
      title: `Делитесь понравившимися новостями в своих ${'\n'}соцсетях`,
    },
  ];

  let secondSteps: Array<IItemList> = [
    {
      number: '04',
      title: 'Выполняйте задания по своему выбору',
      text: `и рассказывайте о своем участии в ${'\n'}приложении`,
    },
    {
      number: '05',
      title: 'Зарабатывайте баллы за свою активность',
      text: `За распространение новостей, за ${'\n'}выполнение заданий, за активность новых ${'\n'}пользователей по вашему приглашению`,
    },
    {
      number: '06',
      title: `Обменивайте баллы на призы`,
      exile: `в Хорошем Маркете`,
      to: "https://goodmarket.oprf.ru/"
    },
  ];
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        Механизм участия <span>пользователя</span>
      </h2>
      <div className={styles.content}>
        <div className={styles.image}>
          <img src={leftImg} alt="Хорошие новости" />
        </div>
        <List list={firstSteps} />
      </div>
      <div className={styles.content}>
        <List list={secondSteps} />
        <div className={styles.image}>
          <img src={rightImg} alt="Хорошие новости" />
        </div>
      </div>
    </div>
  );
};
