import React from 'react';
import { ButtonStoreLink } from '../../../shared/ui/ButtonStoreLink/ButtonStoreLink';
import buttons from '../../../shared/assets/img/buttons-shop.png';
import circle1 from '../../../shared/assets/img/icon-circle1.png';
import circle2 from '../../../shared/assets/img/icon-circle2.png';
import circle3 from '../../../shared/assets/img/icon-circle3.png';
import circle4 from '../../../shared/assets/img/icon-circle4.png';
import frame from '../../../shared/assets/img/img-frame.png';
import styles from './Installation.module.css';


interface IInstallation { }

export const Installation: React.FC<IInstallation> = () => {
  return (
    <div className={styles.wrapper} id="apk">
      <div className={styles.title}>
        <p className={styles.head}>Как загрузить</p>
        <p className={styles.topic}>APK файл</p>
      </div>
      <div className={styles.container}>
        <div className={styles.wrap}>
          <div className={styles.block}>
            <div className={styles.card}>
              <img className={styles.code} src={process.env.mode === "development" ? '/img/qr-codeDev.png' : '/img/qr-code.png'} alt="Qr-code" />
              <ButtonStoreLink to={process.env.mode === "development" ? '/apk/HoroshieNovostyDev.apk' : '/apk/HoroshieNovosty.apk'}>
                <img className={styles.imgs} src={buttons} alt="android" />
              </ButtonStoreLink>
            </div>
            <div className={styles.rede}>
              <img className={styles.img} src={circle1} alt="circle1" />
              <div className={styles.case}>
                <p className={styles.textBig}>Наведите камеру телефона на QR-код</p>
                <p className={styles.text}>или нажмите на кнопку «Cкачать APK для Android» </p>
              </div>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.cardTwo}>
              <div className={styles.dialog}>
                <div className={styles.slip}>
                  <p className={styles.wordBig}>Загрузить файл?</p>
                  <p className={styles.word}>HoroshieNovosty.apk</p>
                  <p className={styles.word}>Файлы этого типа могут нанести вред вашему телефону.
                    Загружайте этот файл только из&nbsp;надежных источников</p>
                </div>
                <div className={styles.actions}>
                  <p className={styles.tactic}>Отмена</p>
                  <p className={styles.tactic}>Загрузить</p>
                </div>
              </div>
            </div>
            <div className={styles.rede}>
              <img className={styles.img} src={circle2} alt="circle2" />
              <div className={styles.case}>
                <p className={styles.textBig}>Скачайте файл для установки в формате APK.</p>
                <p className={styles.text}>При необходимости подтвердите загрузку. Обратите внимание:
                  на&nbsp;вашем телефоне должно быть не&nbsp;менее 1&nbsp;ГБ свободной памяти.</p>
              </div>
            </div>

          </div>
        </div>
        <div className={styles.wrap}>
          <div className={styles.block}>
            <div className={styles.cardTwo}>
              <div className={styles.dialog}>
                <div className={styles.slip}>
                  <p className={styles.wordBig}>Internet</p>
                  <p className={styles.word}>В&nbsp;целях безопасности ваш телефон блокирует установку неизвестных
                    приложений из&nbsp;этого источника. Этот параметр можно изменить в&nbsp;настроках</p>
                </div>
                <div className={styles.actions}>
                  <p className={styles.tactic}>Отмена</p>
                  <p className={styles.tactic}>Настройки</p>
                </div>
              </div>
            </div>
            <div className={styles.rede}>
              <img className={styles.img} src={circle3} alt="circle4" />
              <div className={styles.case}>
                <p className={styles.textBig}>Откройте файл и&nbsp;при необходимости разрешите браузеру устанавливать приложения.</p>
                <p className={styles.text}>Важно! При установке появится сообщение о&nbsp;небезопасном источнике. Не&nbsp;переживайте,
                  это стандартное уведомление. Приложение надёжное и&nbsp;безопасное.</p>
              </div>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.ticket}>
              <div className={styles.last}>
                <div className={styles.slip}>
                  <div className={styles.frame}>
                    <img className={styles.logo} src={frame} alt="frame" />
                    <p className={styles.wordBig}>Хорошие новости</p>
                  </div>
                  <p className={styles.word}>Приложение установлено!</p>
                </div>
                <div className={styles.actions}>
                  <p className={styles.tactic}>Готово</p>
                  <p className={styles.tactic}>Открыть</p>
                </div>
              </div>
            </div>
            <div className={styles.rede}>
              <img className={styles.img} src={circle4} alt="circle4" />
              <div className={styles.case}>
                <p className={styles.textBig}>Готово!</p>
                <p className={styles.text}>После установки откройте приложение и&nbsp;начните им&nbsp;пользоваться.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
