import React from 'react';
import styles from './ListItem.module.css';
import type { IItemList } from '../../list';

export const ListItem: React.FC<IItemList> = ({ number, title, exile, text, to }) => {
  const handleClick = () => {
    if (to) {
      window.open(to, '_blank');
    }
  };
  return (
    <div className={styles.wrapper} >
      <div className={styles.circle}>{number}</div>
      <div>
        <h3 className={styles.title}>{title}</h3>
        <p className={to ? styles.exile : ''} onClick={handleClick}>{exile}</p>
        {text && <p className={styles.text}>{text}</p>}
      </div>
    </div>
  );
};
