import React from 'react';
import styles from './ButtonStoreaAnchor.module.css';
import classNames from 'classnames';

interface IButtonStoreaAnchor
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  to: string;
  css?: string;
}

export const ButtonStoreaAnchor: React.FC<IButtonStoreaAnchor> = ({
  children,
  to,
  css,
}) => {
  let style = classNames(styles.btn, styles.container, css);
  return (
    <a className={style} href={to}>
      {children}
    </a>
  );
};
