import React from 'react';
import styles from './Card.module.css';

interface ICard {
  children: React.ReactNode;
  title: string;
  title_class?: string
  text: string;
  to?: string;
}

export const Card: React.FC<ICard> = ({ children, title, text, to, title_class }) => {
  const handleClick = () => {
    if (to) {
      window.open(to, '_blank');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconBox}>{children}</div>
      <div>
        <h3 className={title_class}>{title}</h3>
        <p className={to ? styles.exile : styles.text} onClick={handleClick}>{text}</p>
      </div>
    </div>
  );
};
