// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlockCards-module__wrapper___NrCmR {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

@media (min-width: 360px) and (max-width: 767px) {

  .BlockCards-module__wrapper___NrCmR {
    flex-wrap: wrap;
    width: 324px;
    gap: 8px;
  }

}`, "",{"version":3,"sources":["webpack://./src/entities/blockCards/ui/BlockCards.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,WAAW;AACb;;AAEA;;EAEE;IACE,eAAe;IACf,YAAY;IACZ,QAAQ;EACV;;AAEF","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  width: 100%;\n}\n\n@media (min-width: 360px) and (max-width: 767px) {\n\n  .wrapper {\n    flex-wrap: wrap;\n    width: 324px;\n    gap: 8px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BlockCards-module__wrapper___NrCmR`
};
export default ___CSS_LOADER_EXPORT___;
