import React from 'react';
import styles from './TextLink.module.css';
import classNames from 'classnames';

interface ITextLink {
  children: React.ReactNode;
  to: string;
  css?: string;
}

export const TextLink: React.FC<ITextLink> = ({ children, to, css }) => {
  let style = classNames(styles.a, css);
  return (
    <a className={style} href={to} target="_blank">
      {children}
    </a>
  );
};
