// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Partners-module__partners___LTS8G {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 34px;
}

.Partners-module__oprf___XFcCu {
  width: 272px;
}

.Partners-module__oprf___XFcCu img {
  width: 100%;
}

.Partners-module__rsv___RtQB_ {
  width: 208px;
}

.Partners-module__rsv___RtQB_ img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .Partners-module__oprf___XFcCu {
    width: 168px;
  }

  .Partners-module__partners___LTS8G {
    justify-content: space-between;
  }

  .Partners-module__rsv___RtQB_ {
    width: 128px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .Partners-module__partners___LTS8G {
    justify-content: space-between;
  }

  .Partners-module__oprf___XFcCu {
    width: 272px;
  }

  .Partners-module__rsv___RtQB_ {
    width: 208px;
  }
}

@media (min-width: 1200px) {
  .Partners-module__partners___LTS8G {
    justify-content: space-between;
  }

  .Partners-module__oprf___XFcCu {
    width: 408px;
  }

  .Partners-module__rsv___RtQB_ {
    width: 313px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/widgets/partners/ui/Partners.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,8BAA8B;EAChC;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,8BAA8B;EAChC;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".partners {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 34px;\n}\n\n.oprf {\n  width: 272px;\n}\n\n.oprf img {\n  width: 100%;\n}\n\n.rsv {\n  width: 208px;\n}\n\n.rsv img {\n  width: 100%;\n}\n\n@media screen and (max-width: 767px) {\n  .oprf {\n    width: 168px;\n  }\n\n  .partners {\n    justify-content: space-between;\n  }\n\n  .rsv {\n    width: 128px;\n  }\n}\n\n@media (min-width: 768px) and (max-width: 1199px) {\n  .partners {\n    justify-content: space-between;\n  }\n\n  .oprf {\n    width: 272px;\n  }\n\n  .rsv {\n    width: 208px;\n  }\n}\n\n@media (min-width: 1200px) {\n  .partners {\n    justify-content: space-between;\n  }\n\n  .oprf {\n    width: 408px;\n  }\n\n  .rsv {\n    width: 313px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partners": `Partners-module__partners___LTS8G`,
	"oprf": `Partners-module__oprf___XFcCu`,
	"rsv": `Partners-module__rsv___RtQB_`
};
export default ___CSS_LOADER_EXPORT___;
