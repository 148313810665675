import { Navbar } from '../entities/navbar';
import { Footer } from '../widgets/footer';
import { MainScreen } from '../widgets/mainScreen';
import { Promotion } from '../widgets/promotion';
import { Installation } from '../widgets/installation';
import { Specifications } from '../widgets/specifications';
import { Steps } from '../widgets/steps';
import './styles/index.css';
import styles from './styles/module/App.module.css';

function App() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Navbar />
        <MainScreen />
      </div>
      <Specifications />
      <Steps />
      <Promotion />
      <Installation />
      <Footer />
    </div>
  );
}

export default App;
